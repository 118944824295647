var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "button"
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "clipboard",
                  rawName: "v-clipboard:copy",
                  value: _vm.textToCopy,
                  expression: "textToCopy",
                  arg: "copy"
                },
                {
                  name: "clipboard",
                  rawName: "v-clipboard:success",
                  value: _vm.onSuccess,
                  expression: "onSuccess",
                  arg: "success"
                },
                {
                  name: "clipboard",
                  rawName: "v-clipboard:error",
                  value: _vm.onFail,
                  expression: "onFail",
                  arg: "error"
                }
              ],
              class: _vm.buttonClass,
              attrs: {
                color: _vm.buttonColor,
                text: _vm.isTextButton,
                icon: _vm.iconButton,
                disabled: _vm.disabled,
                small: _vm.isSmall
              }
            },
            [
              _vm.showIcon
                ? _c("v-icon", { attrs: { small: _vm.isSmall === true } }, [
                    _vm._v("mdi-content-copy")
                  ])
                : _vm._e(),
              _vm.iconButton === false
                ? _c("span", [_vm._v(_vm._s(_vm.buttonName))])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.type === "listItem"
        ? _c(
            "v-list-item",
            {
              directives: [
                {
                  name: "clipboard",
                  rawName: "v-clipboard:copy",
                  value: _vm.textToCopy,
                  expression: "textToCopy",
                  arg: "copy"
                },
                {
                  name: "clipboard",
                  rawName: "v-clipboard:success",
                  value: _vm.onSuccess,
                  expression: "onSuccess",
                  arg: "success"
                }
              ],
              attrs: { "active-class": "" },
              on: {
                click: function($event) {
                  _vm.itemClicked = true
                }
              }
            },
            [
              _c(
                "v-list-item-title",
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-content-copy")
                  ]),
                  _vm._v(" " + _vm._s(_vm.buttonName) + " ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }