<template>
    <div>
        <v-btn
            :color="buttonColor"
            :class="buttonClass"
            :text="isTextButton"
            v-clipboard:copy="textToCopy"
            v-clipboard:success="onSuccess"
            v-clipboard:error="onFail"
            :icon="iconButton"
            v-if="type === 'button'"
            :disabled="disabled"
            :small="isSmall"
        >
            <v-icon v-if="showIcon" :small="isSmall === true">mdi-content-copy</v-icon><span v-if="iconButton === false">{{ buttonName }}</span>
        </v-btn>
        <v-list-item active-class v-if="type === 'listItem'" v-clipboard:copy="textToCopy" v-clipboard:success="onSuccess" @click="itemClicked = true">
            <v-list-item-title>
                <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                {{ buttonName }}
            </v-list-item-title>
        </v-list-item>
    </div>
</template>
<script>
export default {
    name: 'CopyToClipboard',
    props: {
        textToCopy: String,
        buttonClass: String,
        buttonColor: String,
        isTextButton: Boolean,
        buttonName: String,
        iconButton: { type: Boolean, default: false },
        showIcon: { type: Boolean, default: true },
        type: { type: String, default: 'button' },
        disabled: { type: Boolean, default: false },
        isSmall: { type: Boolean, default: true }
    },
    data() {
        return {
            itemClicked: false
        }
    },
    methods: {
        onSuccess: function() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Copied to clipboard!',
                snackBarTimeout: 5000,
                snackBarIcon: 'check_circle'
            })
        },
        onFail: function() {
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Failed to copy to clipboard',
                snackBarTimeout: 5000,
                snackBarIcon: 'error'
            })
        }
    }
}
</script>
